<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card :elevation="isMobile ? '1' : '0'" :width="isMobile ? '350' : '450'" height="480"
                class="mt-md-16 md-mx-0 mx-auto" style="border: 1px solid #57430E">

          <v-card-text>
            <div class="my-6 mx-auto text-center">
              <v-img class="mx-auto" contain width="100" height="100" :src="logo"/>
            </div>
            <!------------ input code here ----------->
            <v-form @submit.prevent="verifyOtp">
              <v-row justify="space-between">
                <v-col sm="2">
                  <code-input v-model="otps[0]"/>
                </v-col>
                <v-col sm="2">
                  <code-input v-model="otps[1]"/>
                </v-col>
                <v-col sm="2">
                  <code-input v-model="otps[2]"/>
                </v-col>
                <v-col sm="2">
                  <code-input v-model="otps[3]"/>
                </v-col>
                <v-col sm="2">
                  <code-input v-model="otps[4]"/>
                </v-col>
                <v-col sm="2">
                  <code-input v-model="otps[5]"/>
                </v-col>
              </v-row>

              <div class="pt-16">
                <v-btn :loading="loading" type="submit" :disabled="!getOTP || getOTP.length < 6" elevation="0" color="primaryDark" style="color: white" width="100%"
                       height="3.5em">
                  Proceed
                  <v-icon end size="32" class="ml-4">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-form>
            <v-btn elevation="0" class="mt-4" color="primaryDark" style="color: white" width="100%"
                   height="3.5em" @click="sendOtp">
              Resend OTP
              <v-icon end size="32" class="ml-4">mdi-restore</v-icon>
            </v-btn>
          </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
  </div>

</template>


<script>
//import CodeInput from '@/components/CodeInput.vue';
import CodeInput from './components/CodeInput.vue';
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import MessageBox from "@ui/components/MessageBox.vue";

export default {
  name: "otp",
  props: ['record', "logo"],
  components: {
    MessageBox,
    CodeInput
  },

  data: () => ({
    messageShow: false,
    loading: false,
    messageType: '',
    message: '',
    otps: ['','','','','','']
  }),

  methods: {
    verifyOtp(){
      this.loading = true
      Api.auth.verifyOtp({
        username: this.record,
        password: this.getOTP
      })
          .then(res => {
            if(res.data.success){
              this.message = "OTP verified"
              this.messageType = 'success'
              this.messageShow = true
              this.$emit('navigate', 3)
            } else{
              this.message = "Invalid OTP"
              this.messageType = 'error'
              this.messageShow = true
            }
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    },

    sendOtp(){
      this.loading = true
      Api.auth.sendOtp({username: this.record})
          .then(res => {
            if(res.data.success){
              this.message = "OTP Sent"
              this.messageType = 'success'
              this.messageShow = true
            } else{
              this.message = "Failed to send OTP"
              this.messageType = 'error'
              this.messageShow = true
            }
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => {
            this.loading = false
            this.reqObj.password = ''
          })
    },
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },

    getOTP: function(){
      return '' + this.otps[0] + this.otps[1] + this.otps[2] + this.otps[3] + this.otps[4] + this.otps[5]
    }
  }
}
</script>
