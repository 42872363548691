<template>
  <v-container class="pa-0 ma-0" fluid style="height: 100vh; width: 100%">
    <div class="d-flex flex-column-reverse flex-md-row-reverse flex-no-wrap pa-0" style="height: 100%; width: 100%">
      <div class="flex-grow-1" style="width: 100%">
        <v-row justify="center" style="height: 100%" class="my-auto">
          <v-col cols="12" md="8" style="height: 100%" v-if="!showForgotPassword">
            <div class="pa-4 d-flex flex-column" style="height: 100%" v-if="!isMfa">
              <div class="mb-4 hidden-sm-and-down"></div>
              <div :class="{ 'primary--text': true, 'mb-8': hasError, 'mb-6': !hasError }">
                <h1 class="header" style="color: #223345" v-if="!isMobile">Login</h1>
              </div>
              <v-alert color="drawerBg" class="white--text" v-if="isMobile">Welcome! Please login below with your user
                ID and password to continue. You can use biometric if you are using a supported device</v-alert>
              <div class="basic--text mb-8" v-else>Welcome! Please login below to continue</div>
              <div v-if="isUsernamePasswordAuth" :class="{ 'q pa-2 rounded qb': isMobile }">
                <div class="mt-3">
                  <v-alert type="info" outlined dismissible dense class="mb-8" v-show="usingOldReg">{{ newRegMessage }}
                  </v-alert>
                  <v-text-field dense outlined placeholder="User ID" v-model="formData.username"></v-text-field>
                  <v-text-field dense outlined :type="showPassword ? 'text' : 'password'" placeholder="Password" class="mb-0"
                    v-model="formData.password" @keyup.enter="login" :append-icon="showPassword ?'mdi-eye-off' : 'mdi-eye'" @click:append="showPassword = ! showPassword"></v-text-field>
                </div>
                <div>
                  <span style="color: #223345" class="ml-1">Forgot password? </span><a href="" class="primary--text"
                    @click.prevent="showForgotPassword = true">Click here</a>
                  <span style="color: #223345" class="ml-1" v-if="isMediumAndUp">or </span> <a href=""
                    class="primary--text" v-on:click.prevent="switchMode" v-if="isMediumAndUp">Switch Mode</a>
                </div>
                <div class="my-6">
                  <v-btn color="drawerBg" rounded elevation="0" type="primary" width="100%" class="white--text"
                    :loading="isLoading" @click="login">Login</v-btn>

                </div>
                <span style="color: #223345" class="">
                  Not yet a student or a returning applicant?
                </span>
                <a href="/application" class="primary--text link">Click here</a>

              </div>
              <div v-else>
                <v-alert v-if="deviceConnected" type="info" color="success">Device detected</v-alert>
                <v-alert v-else type="error">No device detected</v-alert>

                <v-text-field type="text" dense outlined placeholder="User ID" v-model="formData.username"></v-text-field>
                <div class="mb-4">
                  <span style="color: #223345" class="ml-1">Having difficulty? </span> <a href="" class="primary--text"
                    v-on:click.prevent="switchMode">Switch Mode</a>
                </div>
                <v-alert type="info">Place your right or left middle finger on the device once connection is established
                </v-alert>
              </div>

              <v-spacer></v-spacer>
              <div class="basic--text mt-2 text-center">&copy; {{ new Date().getFullYear() }} {{ stateGlobal.institutionName }}</div>
            </div>
            <div class="pa-4 mt-6" v-else :class="{ 'q pa-2 rounded qb': isMobile }">
              <v-alert type="error" class="font-weight-bold my-10">
                New device detected. Verification required to proceed
              </v-alert>
              <div class="mb-4">
                <v-alert type="error" dismissible dense class="mb-8" v-show="hasError">{{ errorMsg }}</v-alert>
                <v-alert type="success" dismissible dense class="mb-8" v-show="!hasError && mfaSent">{{ mfaMsg }}
                </v-alert>
                <v-text-field rounded dense filled outlined placeholder="Provide verification code" class="mb-0"
                  v-model="mfa.code"></v-text-field>
              </div>
              <div class="mb-8">
                <v-btn color="drawerBg" rounded elevation="0" type="primary" width="100%" class="white--text"
                  :loading="isLoading" @click="verifyCode">Verify</v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" style="height: 100%" v-else>
            <forgot-password v-on:close="showForgotPassword = !showForgotPassword" :global-config="stateGlobal"></forgot-password>
          </v-col>
        </v-row>
      </div>
      <div class="flex-grow-1 drawerBg white--text r-side" style="width: 100%" v-if="isMediumAndUp">
        <div class="r-side-overlay pa-0">
          <div class="d-flex flex-column justify-space-between pa-10" style="height: 100%">
            <div class="d-flex flex-row">
              <div>
                <v-img aspect-ratio="1" width="100px" style="border: white 4px solid" class="rounded-circle"
                  :src="logo"></v-img>
              </div>
              <div class="font-weight-bold mt-auto ml-4" style="font-size: 1.5em; text-transform: uppercase;">
                {{ stateGlobal.institutionName }}</div>
            </div>
            <div class="mt-12 descr">
              Welcome to the {{ stateGlobal.institutionName }} school portal. Some services on this portal are
              restricted,
              hence, only authenticated and authorized users can access them.
            </div>
            <v-spacer></v-spacer>
            <div class="font-italic">{{ stateGlobal.motto }}...</div>
          </div>
        </div>
      </div>
    </div>
    <message-box :show="messageShow" :message="message" :type="messageType"
      v-on:close-message="messageShow = !messageShow"></message-box>
    <loading-dialog :show="loading"></loading-dialog>
  </v-container>
</template>

<script>

import ErrorResolver from "@/shared/ErrorResolver";
import Api from "@/main/api";
import MessageBox from "@ui/components/MessageBox";
import Roles from "@/shared/Roles";
import LoginUtil from "../LoginUtil";
import AuthenticationMode from "@/shared/constants/AuthenticationMode";
import LoadingDialog from "@ui/components/LoadingDialog";
import ForgotPassword from "./ForgotPassword";
import Defaults from "@/shared/Defaults";
import Links from "@/shared/constants/Links";
import FingerprintSdk from "@/utils/FingerprintSDK";

export default {
  name: 'Auth',
  components: { ForgotPassword, LoadingDialog, MessageBox },
  data: () => ({
    isLoading: false,
    loading: false,
    hasError: false,
    errorMsg: "",
    mfaMsg: "",
    mfaSent: false,
    messageType: '',
    message: '',
    messageShow: false,
    usingOldReg: false,
    newRegMessage: '',
    changeCourseMessage: '',
    authMode: 0,
    reader: null,
    deviceConnected: false,
    showForgotPassword: false,
    showPassword: false,
    logo: '',
    stateGlobal: {},
    formData: {
      username: "",
      password: ""
    },

    mfa: {
      userId: '',
      code: ''
    },

    isMfa: false, // for multi factor auth

  }),

  methods: {
    login() {
      if (this.formData.password === undefined || this.formData.password === '' || this.formData.username === undefined || this.formData.username === '')
        return
      // start loading
      this.isLoading = true;
      this.usingOldReg = false;

      Api.auth.login(this.formData).then(res => {

        this.hasError = false;
        // if using old reg no.
        if (res.data.message){ // change of programme
          this.usingOldReg = true;
          this.newRegMessage = "Use the username: " + res.data.message + " as you new username";
        } else if (res.data.old) { //
          this.usingOldReg = true;
          this.newRegMessage = "Login to the institutional email account: " + res.data.userId + ' with the password: 12345678 for your student ID'
        }
        // if multi factor enabled
        else if (res.data.mfa) {
          this.isMfa = true
          this.mfa.userId = res.data.userId
        } else {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt);
          LoginUtil.setRefreshToken(res.data.refreshToken);
          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student) || Roles.contains(res.data.roles, Roles.Applicant)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }

      }).catch(err => {
        this.hasError = true
        ErrorResolver.checkErrors(this, err)
      }).finally(() => {
        this.isLoading = false;
      })
    },

    verifyCode() {
      this.isLoading = true
      Api.auth.verifyMfa(this.mfa)
        .then(res => {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt)
          LoginUtil.setRefreshToken(res.data.refreshToken);

          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }).catch(err => {
          ErrorResolver.checkErrors(this, err)
        }).finally(() => {
          this.isLoading = false
        })
    },

    switchMode() {
      if (this.authMode === AuthenticationMode.USERNAME_PASSWORD) {
        this.authMode = AuthenticationMode.BIOMETRIC
        this.initializeDevice()
      } else {
        this.closeScanner()
        this.authMode = AuthenticationMode.USERNAME_PASSWORD
      }
    },

    closeScanner() {
      if (this.reader != null) {
        // this.reader.stopAcquisition();
        // this.reader.off();
        // this.connected = false
      }
    },

    onDeviceConnected(event) {
      console.log("Hey")
      this.deviceConnected = true
    },

    onDeviceDisconnected(event) {
      this.deviceConnected = false
    },

    onReaderError(event) {
      this.deviceConnected = false
    },

    onQuality(event){

    },

    onSampleAcquired(event) {
      let samples = JSON.parse(event.samples);
      this.formData.password = samples[0].replace(/-/g, '+').replace(/_/g, '/') // from base64url to base64
      if (!this.loading && this.formData.username !== '' && this.formData.username != null && this.authMode === AuthenticationMode.BIOMETRIC) {
        this.loading = true
        Api.auth.bioAuth(this.formData)
          .then(res => {
            if (res.data.success) {
              this.message = 'Verified'
              this.messageType = 'success'
              this.messageShow = true
              // save to cookie
              LoginUtil.setCookie("ctoken", res.data.details.jwt, 2);
              if (Roles.contains(res.data.details.roles, Roles.Admin)) {
                window.location.href = '/main';
              } else if (Roles.contains(res.data.details.roles, Roles.Student)) {
                window.location.href = '/student';
              } else {
                window.location.href = '/officer';
              }
            } else {
              this.message = 'Verification failed'
              this.messageType = 'error'
              this.messageShow = true
              this.loading = false
            }
          }).catch(err => {
            ErrorResolver.checkErrors(this, err)
            this.loading = false
          })
      }
    },

    initializeDevice() {
      // initialize finger print object
      this.reader = new FingerprintSdk(
          this.onDeviceConnected,
          this.onDeviceDisconnected,
          this.onSampleAcquired,
          this.onQuality,
          this.onReaderError
      );

      // start acquisition
      this.reader.startCapture()
    },

    fetchGlobal() {

      Api.global.fetch().then((res) => {
        this.stateGlobal = res.data;
        this.logo = Defaults.api + Links.global.logo;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => this.loading = false)
    },
  },



  computed: {
    isUsernamePasswordAuth: function () {
      return this.authMode === AuthenticationMode.USERNAME_PASSWORD
    },

    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function () {
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  created() {
    this.fetchGlobal()
  }
}
</script>

<style scoped lang="css">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.vr-link:hover {
  text-decoration: underline !important;
  color: whitesmoke !important;
}

.r-side {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../resources/login-bg.jpg");
}

.r-side-overlay {
  background-color: #016b4be7;
  width: 100%;
  height: 100%;
}

.descr {
  font-family: 'Open Sans', sans-serif;
}

.q {
  background-color: white;
}

.qb {
  border: #d7e2e8 1px solid;
}

@media only screen and (min-width : 360px) and (max-width: 768px) {
  .link {
    padding-right: 30px;
    /* margin-left: 18px; */
    /* text-align: center; */
  }
}
</style>
