<template>
  <v-app>
    <v-main>
      <AuthLafia></AuthLafia>
    </v-main>
  </v-app>
</template>

<script>
import AppTypes from "shared/constants/apptypes";
import Api from "main/api";
import AuthLafia from "./Auth.vue";
import LoginUtil from "@/login/LoginUtil";

export default {
  name: "App",
  components: {AuthLafia},
  data: () => ({

  }),
  created() {
    LoginUtil.resetAccessToken(); 
    LoginUtil.resetRefreshToken(); 

   
  }, 


  mounted() {
    this.fetchGlobal(); 
  },


  methods: {
    fetchGlobal() {
      let app = AppTypes.CURRENT === AppTypes.LAFIA; 
      Api.global.fetch().then((res) => {
        // console.log(res.data); 
        if(app) {
          document.title = res.data.institutionName; 
        }

        return undefined; 
      }).catch((err) => ErrorResolver.checkErrors(this, err))
    }
  },
}
</script>

<style lang="scss">
@import "@/resources/styles/main.scss";
</style>
