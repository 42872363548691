<template>
    <div class="results">
        <a v-for="(result, index) in results" :key="index" :id="`result-${index}`">
            <div class="links">
                                <h1 v-html="result.name"/>
                                <p v-html="result.occupation"/>
                            </div>

                        </a>

                        <div v-if="results.length" />

        <a v-else class="d-flex mx-auto">No results</a>
    </div>
</template>

<script>
export default {
    name: "SearchResult", 
    emits: ["close"], 
    props: ["results", "selected"], 
}
</script>


<style lang="scss" scoped>
$input-color: #333;
$input-background: #f6f9fc;
$active-color: #85C226;

.results {
    position: relative;
    display: flex;
    padding-top: 20px;

    h1 {
        font-size: 1.2em;
    }

    a {
        color: gray;
        width: 100%;
        cursor: pointer;
        display: flex;
        text-align: middle;
        // align-content: middle;

        .links {
            width: 100%;
            padding: 5px 20px 0px 20px;
        }

        :hover {
            background-color: $input-background;
        }

        p {
            font-size: 0.6em;
            line-height: 0.8em;
            
        }
    }
    
}
</style>