<template>
    <div>
        <input
            class="modal-input"
            placeholder="Search..." 
            type="text"
            ref="search"
            @keypress.esc="$emit('close')"
         />
    </div>

</template>

<script>
export default {
    name: "SearchBox", 
    emits: ["close"], 

    created() {
        console.log(this.$refs.search.focus()); 
    }
}
</script>


<style lang="scss" scoped>
$input-color: #333;
$input-background: #f6f9fc;
$active-color: #85C226;
$icon-color: darken($input-background, 30%);
.modal-input {
    width: 100%;
    padding: 6px 30px 6px 32px;
    font-size: 16px;
    font-weight: normal;  
    height: 38px;
    color: $input-color;  
    background-color: $input-background;
    border: 1px solid $input-background;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out;
    &:focus {
        outline: 0;
        box-shadow: none;
        background-color: lighten($input-background, 25%);
        border-color: $active-color;
    }
}


.search-icon {
    color: $icon-color;
    position: absolute;
    &.search {
        bottom: 23em;
        box-sizing: border-box;
        display: block;
        width: 16px;
        height: 16px;
        border: 2px solid;
        border-radius: 100%;
        margin-left: 10px;
        margin-bottom: -0.5em;
    }

    &.search::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      border-radius: 3px;
      width: 2px;
      height: 7px;
      background: $icon-color;
      transform: rotate(-45deg);
      top: 11px;
      left: 12px;
    }
}
</style>