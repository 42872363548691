<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card :elevation="isMobile ? '1' : '0'" :width="isMobile ? '350' : '450'" height="480"
          class="mt-md-16 md-mx-0 mx-auto" style="border: 1px solid #57430E">
          <v-card-text>
            <div class="my-6 mx-auto text-center">
              <v-img class="mx-auto" contain width="100" height="100" :src="logo" />
            </div>
            <v-text-field variant="outlined" v-model="formData.username" :readonly="loading" color="primaryDark"
              density="comfortable" placeholder="Username" prepend-icon="mdi-account" />
            <v-text-field color="primaryDark" :type="showPassword ? 'text' : 'password'" v-model="formData.password" @keyup.enter="login"
              density="comfortable" variant="outlined" :readonly="loading" dense placeholder="Password"
              prepend-icon="mdi-lock" :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye-settings'"
              @click:append-inner="showPassword = !showPassword" />

            <div class="pt-8 mx-auto">
              <v-btn style="color: white;" elevation="0" type="submit" :disabled="!formData.username || !formData.password || loading"
                color="primaryDark" width="100%" height="4em" @click="login">
                Login
              </v-btn>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="12" md="12" class="py-5">
                <p class="text-center primary--text" style="cursor: pointer" v-on:click="$emit('navigate', 1)">
                  Forgot Password
                </p>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <loading-dialog :show="isLoading"></loading-dialog>
    <message-box :show="messageShow" :message="message" :type="messageType"
      v-on:close-message="messageShow = !messageShow"></message-box>
    <confirmation-box :message="newRegMessage" positive-text="Ok" negative-text=" " title="Info"
                      :show="usingOldReg" v-on:positive="usingOldReg = false" v-on:negative="usingOldReg = false">
    </confirmation-box>
  </div>
</template>


<script>
import LoadingDialog from "ui/lafia/components/LoadingDialog.vue";
import MessageBox from "@ui/components/MessageBox.vue";
import Api from "@/main/api";
import LoginUtil from "../LoginUtil";
import Roles from "@/shared/Roles";
import ErrorResolver from "@/shared/ErrorResolver";
import Defaults from "@/shared/Defaults";
import Links from "@/shared/constants/Links";
import ConfirmationBox from "@/components/ConfirmationBox.vue";

export default {
  name: 'Login',
  props: ["logo"],
  components: {ConfirmationBox, LoadingDialog, MessageBox },
  data: () => ({
    isLoading: false,
    loading: false,
    hasError: false,
    errorMsg: "",
    mfaMsg: "",
    mfaSent: false,
    messageType: '',
    message: '',
    messageShow: false,
    usingOldReg: false,
    newRegMessage: '',
    authMode: 0,
    reader: null,
    deviceConnected: false,
    showForgotPassword: false,
    showPassword: false,
    stateGlobal: {},
    formData: {
      username: "",
      password: ""
    },
    mfa: {
      userId: '',
      code: ''
    },

    isMfa: false, // for multi factor auth
  }),

  methods: {
    login() {
      if (this.formData.password === undefined || this.formData.password === '' || this.formData.username === undefined || this.formData.username === '')
        return
      // start loading
      this.isLoading = true;
      this.usingOldReg = false;

      Api.auth.login(this.formData).then(res => {

        this.hasError = false;
        // if using old reg no.
        if (res.data.old) {
          this.usingOldReg = true;
          this.newRegMessage = "Login using the following ID: " + res.data.message + '. Password remains same as your applicant password'
        }
        // if multi factor enabled
        else if (res.data.mfa) {
          this.isMfa = true
          this.mfa.userId = res.data.userId
        } else {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt);
          LoginUtil.setRefreshToken(res.data.refreshToken);
          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student) || Roles.contains(res.data.roles, Roles.Applicant)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }

      }).catch(err => {
        this.hasError = true
        ErrorResolver.checkErrors(this, err)
      }).finally(() => {
        this.isLoading = false;
      })
    },

    verifyCode() {
      this.isLoading = true
      Api.auth.verifyMfa(this.mfa)
        .then(res => {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt)
          LoginUtil.setRefreshToken(res.data.refreshToken);

          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }).catch(err => {
          ErrorResolver.checkErrors(this, err)
        }).finally(() => {
          this.isLoading = false
        })
    },

    fetchGlobal() {
      Api.global.fetch().then((res) => {
        this.stateGlobal = res.data;
        // this.logo = Defaults.api + Links.global.logo;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => this.loading = false)
    },
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
}
</script>
