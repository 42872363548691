<template>
  <div>
    <div v-if="!isMobile">
      <x-dialog :show="show" maxwidth="480" scrollable  v-if="!isMobile">
      <x-card>
        <x-card-text>
          <!--<h3 class="text&#45;&#45;black font-weight-bold font-weight-black mb-4" style="width: 100%; border-bottom: 1px solid black">Hello</h3>-->
          <!--All Post UTME issues or challenges should be forwarded to <a href="mailto:putme@fulafia.edu.ng">putme@fulafia.edu.ng</a> starting with JAMB or FULUG number followed by Issues or challenges <br/>-->
          <!--All issues/challenges will be addressed and replied to same email address within 24-48 hrs-->
        </x-card-text>
      </x-card>
      </x-dialog>
    </div>


    <div v-else-if="isMobile">
      <swipe-modal v-model="show" style="z-index: 999">
        <!--All Post UTME issues or challenges should be forwarded to <a href="mailto:putme@fulafia.edu.ng">putme@fulafia.edu.ng</a> starting with JAMB or FULUG number followed by Issues or challenges <br/>-->
        <!--All issues/challenges will be addressed and replied to same email address within 24-48 hrs-->
      </swipe-modal>
    </div>

  </div>
</template>

<script>
import XDialog from "./XDialog.vue";
import XCard from "./XCard.vue";
import XCardTitle from "./XCardTitle.vue";
import XButton from "../widgets/XButton.vue";
import XCardAction from "./XCardAction.vue";
import XCardText from './XCardText.vue';
import SwipeModal from 'transcript/components/SearchModal/index.vue';

export default {
  name: "Announcement",
  props: {
    announcements: {
      type: Array
    },
    show: Boolean
  },
  data: () => ({

  }),
  components: {
    XCardAction,
    XButton,
    XCardTitle,
    XCard,
    XDialog,
    XCardText,
    SwipeModal
  },

  computed: {
    isMobile: function() {
      return this.$vuetify.breakpoint.xs;
    }
  },

  created() {
    this.show = true;
  }
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    position: absolute;
    justify-items: start;
    top: 0;
    right: 0;
    height: 100%;
    width: 25em;
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;
  }


}
</style>
