<template>
  <v-app style="width: 100%">
    <v-app-bar dense color="white" v-if="isMobile" :app="true" elevate-on-scroll>
      <v-list-item class="mr-2 ml-0 pl-0">
        <v-list-item-icon>
           <v-img aspect-ratio="1" width="50px" :src="logo"></v-img>
        </v-list-item-icon>

        <v-list-item-title class="title mx-5">School Portal</v-list-item-title>
      </v-list-item>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main style="width: 100%; overflow-x: hidden;" :class="{'g': isMobile,'q': isMediumAndUp}">
      <Login/>
    </v-main>
  </v-app>
</template>

<script>
import AppTypes from 'shared/constants/apptypes';
import Login from './components/Auth';
import Api from '@/main/api';
import Links from '@/shared/constants/Links';
import Defaults from '@/shared/Defaults';
import ErrorResolver from '@/shared/ErrorResolver';
import LoginUtil from "@/login/LoginUtil";
export default {
  name: 'App',

  components: {
    Login,
  },

  data: () => ({
    logo: '',
    title: ""
  }),

  methods:{
    fetchGlobal() {
      const app = AppTypes.CURRENT === AppTypes.LAFIA;
      Api.global.fetch().then((res) => {
        this.logo = Defaults.api + Links.global.logo;

        if(app){
          document.title = res.data.institutionName;
        }

        return undefined;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
    },
  },

  mounted() {
    this.fetchGlobal();
  },


  computed: {
    isMobile: function(){
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function(){
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  created() {
    LoginUtil.resetAccessToken()
    LoginUtil.resetRefreshToken()
  },

};
</script>

<style scoped>
  .g{
    background-color: #F5F5F5;
  }

  .q{
    background-color: white;
  }
</style>
